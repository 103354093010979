import React, {useRef, useState, useEffect} from 'react';
import {Index as Animation} from './pages/animations';

const App = function () {
  // tc, sc, en
  const [lang, setLang] = useState('tc');
  const [current, setCurrent] = useState({});
  const canvasRef = useRef(null);
  const animation = Animation.getInstance();

  useEffect(() => {
    closeShadow();

    animation.start();

    canvasRef.current.appendChild(animation.renderer.domElement);

    window.self = animation;

    const data = {
      query:
        '{\n  albums(page: 1, pageSize: 1000) {\n    edges {\n      node {\n        name\n        cover\n        author\n        releaseDate\n        description\n        details\n      }\n    }\n  }\n}\n',
      variables: null,
    };

    fetch('https://api.pulsgarney.com/graphql/', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        animation.setData(
          (data?.data?.albums?.edges || []).map(({node}) => node),
        );

        const _ = {};

        // eslint-disable-next-line array-callback-return
        (data?.data?.albums?.edges || []).map(({node}) => {
          if (node['cover']) _[node['cover']] = node;
        });

        animation.onSelect((r) => {
          setCurrent(_[r] || {});
          openShadow();
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openShadow = () => {
    animation.enableScroll(false);

    document.querySelectorAll('.shadow').forEach((child) => {
      child.style.display = 'block';
    });

    const timeout = setTimeout(() => {
      document.querySelectorAll('.shadow').forEach((child) => {
        child.classList.add('show');
        child.classList.remove('hide');
      });
      document.querySelectorAll('.container').forEach((child) => {
        child.classList.add('show');
        child.classList.remove('hide');
      });

      clearTimeout(timeout);
    }, 100);
  };

  const closeShadow = () => {
    animation.enableScroll(true);

    document.querySelectorAll('.shadow').forEach((child) => {
      child.classList.add('hide');
      child.classList.remove('show');
    });
    document.querySelectorAll('.container').forEach((child) => {
      child.classList.add('hide');
      child.classList.remove('show');
    });

    const timeout = setTimeout(() => {
      document.querySelectorAll('.shadow').forEach((child) => {
        child.style.display = 'none';
      });

      clearTimeout(timeout);
    }, 300);
  };

  return (
    <>
      <header>
        <div>
          <span onClick={() => setLang('tc')}>🇭🇰</span>
          <span onClick={() => setLang('sc')}>🇨🇳</span>
          <span onClick={() => setLang('en')}>🇬🇧</span>
        </div>
      </header>
      <div ref={canvasRef} />
      <div className='shadow' onClick={closeShadow} />
      <div className='container'>
        <div>
          <h1>{current['name'] || 'Title'}</h1>
          <pre>
            <p
              style={{
                color: '#888888',
                textAlign: 'right',
                textShadow: 'none',
              }}
            >
              {current['releaseDate']
                ? `${current['author'] + ' | ' + current['releaseDate']}`
                : ''}
            </p>
            <br />
            <br />
            <p>
              {current['details'] !== null
                ? JSON.parse(current['details'] || '{}')[`description_${lang}`]
                : current['description'] || ''}
            </p>
            <br />
            <br />
          </pre>
        </div>
      </div>
    </>
  );
};

export default App;
