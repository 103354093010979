import * as THREE from 'three';
import {TWEEN} from 'three/examples/jsm/libs/tween.module.min';
// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {WEBGL} from './WebGL';
import {Items} from './items';

let instance = null;

export class Index {
  constructor() {
    this.data = {};
    this.groups = {};
    this.objects = [];
    this.window = global.window;
    this.albumMaxCount = 3;
    this.albumListHeight = 0;
    this.albumPositionOffset = {
      1: 15,
      2: 22.5,
      3: 30,
      4: 37.5,
      5: 45,
    };

    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.items = new Items(this.window, this);

    this.scene = this.getScene();
    this.camera = this.getCamera();
    this.renderer = this.getRenderer();

    this.calcAlbumMaxCount();
    this.prepareLights();

    // Anything new goes after the setData

    // new OrbitControls(this.camera, this.renderer.domElement);
  }

  setData(data) {
    this.createScene(data);
    this.gatheringObjects();
    this.bindEvents();

    this.data['scroll'] = true;
  }

  onSelect(fn) {
    if (typeof fn === 'function') this.data['onSelect'] = fn;
  }

  static getInstance() {
    if (!instance) instance = new Index();

    return instance;
  }

  enableScroll(isEnabled) {
    this.data['scroll'] = isEnabled;
  }

  getCamera() {
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );

    camera.position.set(0, 0, -15);

    return camera;
  }

  getScene() {
    const scene = new THREE.Scene();

    scene.fog = new THREE.FogExp2(0x000000, 0.005);

    return scene;
  }

  createScene(urls = []) {
    // Create the "Plane"
    const planeGroup = this.createGroup('plane');
    const plane = this.items.getPlane();
    planeGroup.position.set(0, 0, -44.3);
    planeGroup.add(plane);

    // Create album wall
    const albumGroup = this.createGroup('album');
    albumGroup.position.set(0, 0, -45);

    let y = 10;
    const albums = this.items.getAlbums(urls);

    for (let i = 0; i < albums.length; i++) {
      const album = albums[i];

      album.position.set(
        15 * ((i % this.albumMaxCount) + 1) -
          this.albumPositionOffset[this.albumMaxCount],
        y,
        1,
      );
      albumGroup.add(album);

      if ((i % this.albumMaxCount) + 1 === this.albumMaxCount) y -= 15;
    }

    // Set the max scroll height
    this.albumListHeight = -y;
  }

  getRenderer() {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    renderer.setPixelRatio(this.window.devicePixelRatio);
    renderer.setSize(this.window.innerWidth, this.window.innerHeight);

    return renderer;
  }

  prepareLights() {
    const lightGroup = this.createGroup('light');

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
    ambientLight.position.set(0, 0, 0);
    lightGroup.add(ambientLight);

    // Centre
    const spotLight1 = new THREE.SpotLight(0xffffff, 0.15);
    spotLight1.target = new THREE.Object3D();
    lightGroup.add(spotLight1);
    lightGroup.add(spotLight1.target);
    spotLight1.position.set(0, 10, 50);
    spotLight1.target.position.set(15, 15, -30);

    const spotLight2 = new THREE.SpotLight(0xffffff, 0.15);
    spotLight2.target = new THREE.Object3D();
    lightGroup.add(spotLight2);
    lightGroup.add(spotLight2.target);
    spotLight2.position.set(-50, 10, 60);
    spotLight2.target.position.set(-7, -5, -20);

    const spotLight3 = new THREE.SpotLight(0xffffff, 0.2);
    spotLight3.target = new THREE.Object3D();
    lightGroup.add(spotLight3);
    lightGroup.add(spotLight3.target);
    spotLight3.position.set(20, -10, 100);
    spotLight3.target.position.set(15, 5, -30);

    // Right
    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.25);
    directionalLight1.castShadow = true;
    directionalLight1.shadow.camera.near = 10;
    directionalLight1.shadow.camera.far = 200;
    directionalLight1.shadow.camera.top = -40;
    directionalLight1.shadow.camera.bottom = 40;
    directionalLight1.shadow.camera.left = -40;
    directionalLight1.shadow.camera.right = 40;
    directionalLight1.shadow.mapSize.width = 2048;
    directionalLight1.shadow.mapSize.height = 2048;
    directionalLight1.target = new THREE.Object3D();
    lightGroup.add(directionalLight1);
    lightGroup.add(directionalLight1.target);
    directionalLight1.position.set(50, 0, 20);
    directionalLight1.target.position.set(30, 10, -30);

    // Left
    const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.4);
    directionalLight2.castShadow = true;
    directionalLight2.shadow.camera.near = 10;
    directionalLight2.shadow.camera.far = 200;
    directionalLight2.shadow.camera.top = -50;
    directionalLight2.shadow.camera.bottom = 50;
    directionalLight2.shadow.camera.left = -50;
    directionalLight2.shadow.camera.right = 50;
    directionalLight2.shadow.mapSize.width = 2048;
    directionalLight2.shadow.mapSize.height = 2048;
    directionalLight2.target = new THREE.Object3D();
    lightGroup.add(directionalLight2);
    lightGroup.add(directionalLight2.target);
    directionalLight2.position.set(-80, 50, 80);
    directionalLight2.target.position.set(10, -20, -30);

    this.scene.add(lightGroup);
  }

  createGroup(name) {
    this.groups[name] = new THREE.Group();
    this.groups[name].name = ['grp', name].join('.');

    this.scene.add(this.groups[name]);

    return this.groups[name];
  }

  bindEvents() {
    // Handle wheel events
    let position = 0;
    const albumGroup = this.groups['album'];
    const onMouseWheel = (e) => {
      if (albumGroup && this.data['scroll']) {
        position += e.deltaY * 0.04;

        if (this.albumListHeight > position && position > 0)
          albumGroup.position.set(
            albumGroup.position.x,
            position,
            albumGroup.position.z,
          );
        else position = albumGroup.position.y;
      }
    };
    this.window.addEventListener('wheel', onMouseWheel, false);

    // Handle touch events
    let touchPosition = 0;
    const onTouchStart = ({touches: [{pageY = 0}] = [{}]}) => {
      touchPosition = pageY;
    };
    const onTouchMove = ({touches: [{pageY = 0}] = [{}]}) => {
      const difference = touchPosition - pageY;

      touchPosition = pageY;

      onMouseWheel({deltaY: difference * 2});
    };
    const onTouchEnd = () => {
      touchPosition = 0;
    };
    this.window.addEventListener('touchstart', onTouchStart, false);
    this.window.addEventListener('touchmove', onTouchMove, false);
    this.window.addEventListener('touchend', onTouchEnd, false);

    // Handle resize events
    const onResize = () => {
      this.camera.aspect = this.window.innerWidth / this.window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(this.window.innerWidth, this.window.innerHeight);
    };
    this.window.addEventListener('resize', onResize, false);

    const onMouseMove = (e) => {
      e.preventDefault();

      // Set mouse position between -1, 1
      this.mouse.x = (e.clientX / this.window.innerWidth) * 2 - 1;
      this.mouse.y = -(e.clientY / this.window.innerHeight) * 2 + 1;

      this.setCursor(this.data['hovered']);
    };
    this.renderer.domElement.addEventListener('mousemove', onMouseMove, false);
    this.renderer.domElement.addEventListener(
      'mousemove',
      this.raycast.bind(this),
      false,
    );

    // Handle click events
    const onClick = (e) => {
      if (this.data['hovered'] && this.data['onSelect']) {
        this.data['onSelect'](this.data['hovered']);
        this.data['hovered'] = null;
        this.setCursor(null);
      }
    };
    this.window.addEventListener('click', onClick, false);
  }

  setCursor(show = true) {
    if (this.data.pointer !== show)
      this.window.document.body.style.cursor = show ? 'pointer' : 'default';

    this.data.pointer = show;
  }

  calcAlbumMaxCount() {
    const innerWidth = this.window.innerWidth;

    if (innerWidth < 600) this.albumMaxCount = 1;
    else if (innerWidth < 960) this.albumMaxCount = 2;
    else if (innerWidth < 1280) this.albumMaxCount = 3;
    else if (innerWidth < 1920) this.albumMaxCount = 4;
    else this.albumMaxCount = 5;
  }

  gatheringObjects() {
    this.scene.traverse((obj) => obj.isMesh && this.objects.push(obj));
  }

  raycast(event) {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.groups['album'].children,
    );

    for (let i = 0; i < intersects.length; i++) {
      const object = intersects[i].object;

      if (object.isMesh && object.parent.isGroup)
        this.data['hovered'] = object.parent.name.split('*#$*')[1];
    }

    if (intersects.length < 1) this.data['hovered'] = null;
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));

    TWEEN.update();

    this.camera.lookAt(new THREE.Vector3(0, 0, -30));
    this.renderer.render(this.scene, this.camera);
  }

  start() {
    if (WEBGL.isWebGLAvailable()) {
      this.animate();
    } else {
      const warning = WEBGL.getWebGLErrorMessage();

      this.window.document.body.appendChild(warning);
    }
  }
}
