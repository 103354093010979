import * as THREE from 'three';

export class Items {
  constructor(window, self) {
    this.self = self;
    this.window = window;
  }

  getWoodMaterial(transparent = false) {
    const loader = new THREE.TextureLoader();

    loader.setPath('textures/wood/');

    const ambientOcclusion = loader.load('a.jpg');
    const baseColor = loader.load('b0.jpg');
    const height = loader.load('h.png');
    const normal = loader.load('n.jpg');
    const roughness = loader.load('r.jpg');
    const material = new THREE.MeshStandardMaterial({
      map: baseColor,
      normalMap: normal,
      displacementMap: height,
      displacementScale: -1,
      roughnessMap: roughness,
      roughness: 1,
      aoMap: ambientOcclusion,
      side: THREE.DoubleSide,
      opacity: 0,
      transparent,
    });

    return material;
  }

  getAlbumCover(url) {
    const texture = new THREE.TextureLoader().load(url);

    return new THREE.MeshStandardMaterial({map: texture});
  }

  getAlbumEdgeDark() {
    return new THREE.MeshPhongMaterial({color: 0x222222});
  }

  getAlbumEdgePattern() {
    const canvas = this.window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 20;
    canvas.height = 20;

    Array(20)
      .fill(null)
      // eslint-disable-next-line array-callback-return
      .map((_, i) => {
        ctx.fillStyle = ['#222222', '#000000'][i % 2];
        ctx.fillRect(0, i + 1, 20, 1);
      });

    const texture = new THREE.CanvasTexture(canvas);

    return new THREE.MeshPhongMaterial({map: texture});
  }

  getAlbums(urls) {
    const albums = [];

    const edgeDarkMaterial = this.getAlbumEdgeDark();
    const edgePatternMaterial = this.getAlbumEdgePattern();

    const coverGeometry = new THREE.BoxGeometry(10, 10, 0.5);
    const hingeGeometry = new THREE.BoxGeometry(1, 10, 0.5);

    for (let {cover: url} of urls) {
      const albumCoverMaterial = this.getAlbumCover(url);

      const coverMaterials = [
        edgeDarkMaterial,
        edgeDarkMaterial,
        edgePatternMaterial,
        edgePatternMaterial,
        albumCoverMaterial,
        edgeDarkMaterial,
      ];
      const cover = new THREE.Mesh(coverGeometry, coverMaterials);
      cover.castShadow = true;

      const hingeMaterials = [
        edgeDarkMaterial,
        edgeDarkMaterial,
        edgePatternMaterial,
        edgePatternMaterial,
        edgeDarkMaterial,
        edgeDarkMaterial,
      ];
      const hinge = new THREE.Mesh(hingeGeometry, hingeMaterials);
      hinge.castShadow = true;
      hinge.position.set(-5.5, 0, 0);

      const album = new THREE.Group();

      album.name = ['album', url].join('*#$*');
      album.add(hinge);
      album.add(cover);

      albums.push(album);
    }

    return albums;
  }

  getPlane() {
    // const factor = 30;
    // const geometry = new THREE.BoxGeometry(
    //   4.5 * factor,
    //   3 * factor,
    //   3 * factor,
    // );
    const geometry = new THREE.PlaneGeometry(120, 60);
    const material = this.getWoodMaterial();
    // const transparentMaterial = this.getWoodMaterial(true);

    material.map.repeat.set(5, 5);
    material.map.rotation = THREE.MathUtils.degToRad(-90);
    material.map.wrapS = material.map.wrapT = THREE.RepeatWrapping;

    const mesh = new THREE.Mesh(geometry, material);

    mesh.receiveShadow = true;
    mesh.position.set(0, 0, 0);

    return mesh;
  }
}
